// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acik-kaynak-film-js": () => import("./../../../src/pages/acik-kaynak-film.js" /* webpackChunkName: "component---src-pages-acik-kaynak-film-js" */),
  "component---src-pages-destek-ol-js": () => import("./../../../src/pages/destek-ol.js" /* webpackChunkName: "component---src-pages-destek-ol-js" */),
  "component---src-pages-galeri-js": () => import("./../../../src/pages/galeri.js" /* webpackChunkName: "component---src-pages-galeri-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kamera-arkasi-js": () => import("./../../../src/pages/kamera-arkasi.js" /* webpackChunkName: "component---src-pages-kamera-arkasi-js" */),
  "component---src-pages-sahneler-js": () => import("./../../../src/pages/sahneler.js" /* webpackChunkName: "component---src-pages-sahneler-js" */)
}

